<template>
  <div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)">
      <b-row>
        <b-col>
          <b-form-group
            id="dateRanges-group"
            label="Périodes"
            label-for="dateRanges"
          >
            <b-form-select v-model="selectedDateRange" @change="onChoiceChanged(false)" id="dateRanges">
              <b-select-option v-for="dateRange of dateRanges" :key="dateRange.id" :value="dateRange">
                {{ dateRange.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="start-date-group"
            :label="prefixLabel + 'Du'"
            label-for="start-date"
            description="Date de début de période"
          >
            <b-form-input
              type="date" id="start-date" v-model="startDate" :disabled="!isFreeDateRange"
              @change="onDateRangeChanged(false)"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="end-date-group"
            label="Au"
            label-for="end-date"
            description="Date de fin de période"
          >
            <b-form-input
              type="date" id="end-date" v-model="endDate" :disabled="!isFreeDateRange"
              @change="onDateRangeChanged(false)"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeDateRange } from '@/types/base'
import { makeSchoolYearEx } from '@/types/schools'

export default {
  name: 'DateRangeSelector',
  mixins: [BackendMixin],
  props: {
    freePeriod: {
      type: Boolean,
      defaultValue: false,
    },
    hasToday: {
      type: Boolean,
      defaultValue: false,
    },
    initWithLastSchoolYear: {
      type: Boolean,
      defaultValue: false,
    },
    civilFirst: {
      type: Boolean,
      defaultValue: false,
    },
    prefixLabel: {
      type: String,
      default: '',
    },
    initWithFree: {
      type: Array,
      defaultValue: [],
    },
  },
  components: {
    LoadingGif,
  },
  data() {
    return {
      selectedDateRange: null,
      loadingName: 'dateRange-selector',
      dateRanges: [],
      startDate: null,
      endDate: null,
    }
  },
  watch: {
  },
  computed: {
    isFreeDateRange() {
      return this.selectedDateRange && this.selectedDateRange.id === 0
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      this.dateRanges = [makeDateRange({ id: 0, name: 'Période libre', })]
      if (this.hasToday) {
        const today = moment().format('YYYY-MM-DD')
        this.dateRanges = this.dateRanges.concat(
          [makeDateRange({ id: -1, name: 'Aujourd\'hui', start_date: today, end_date: today, })]
        )
      }
      await this.loadDateRanges()
      let selectedIndex
      if (this.civilFirst) {
        if (moment().month() > 1) {
          selectedIndex = 1
        } else {
          selectedIndex = 2
        }
      } else {
        if (this.hasToday) {
          selectedIndex = (!this.freePeriod || this.initWithLastSchoolYear) ? 2 : 1
        } else {
          selectedIndex = (!this.freePeriod || this.initWithLastSchoolYear) ? 1 : 0
        }
      }
      if (selectedIndex < this.dateRanges.length) {
        this.selectedDateRange = this.dateRanges[selectedIndex]
        if (this.isFreeDateRange && this.initWithFree && this.initWithFree.length === 2) {
          this.startDate = this.initWithFree[0]
          this.endDate = this.initWithFree[1]
        }
        this.onChoiceChanged(true)
      }
    },
    getSchoolYears(years) {
      const items = []
      for (let elt of years) {
        const schoolYear = {
          id: elt.id,
          name: 'Année scolaire ' + elt.name,
          start_date: elt.startDate,
          end_date: elt.endDate,
        }
        items.push(makeDateRange(schoolYear))
      }
      return items
    },
    getFullYears(years) {
      const items = []
      for (let elt of years) {
        const thisYear = elt.startYear + 1
        const fullYearDateRange = {
          id: -thisYear,
          name: 'Année civile ' + thisYear,
          start_date: '' + thisYear + '-01-01',
          end_date: '' + thisYear + '-12-31',
        }
        items.push(makeDateRange(fullYearDateRange))
      }
      return items
    },
    async loadDateRanges() {
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', '/api/school-years/')
      try {
        let dateRanges = []
        let resp = await backendApi.callApi()
        const years = resp.data.map(elt => makeSchoolYearEx(elt))
        if (this.civilFirst) {
          dateRanges = this.getFullYears(years).concat(this.getSchoolYears(years))
        } else {
          dateRanges = this.getSchoolYears(years).concat(this.getFullYears(years))
        }
        this.dateRanges = this.dateRanges.concat(dateRanges)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    onChoiceChanged(init = false) {
      if (this.selectedDateRange.startDate) {
        this.startDate = this.selectedDateRange.startDate
      }
      if (this.selectedDateRange.endDate) {
        this.endDate = this.selectedDateRange.endDate
      }
      this.onDateRangeChanged(init)
    },
    onDateRangeChanged(init) {
      this.$emit(
        'change',
        {
          startDate: this.startDate,
          endDate: this.endDate,
          isFreePeriod: this.isFreeDateRange,
          init: init,
        }
      )
    },
  },
}
</script>
<style scoped lang="less">
</style>
